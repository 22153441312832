// eslint-disable-next-line import/no-extraneous-dependencies
import { UserRole } from '@nicejob-library/data-platform-implementation/entities/common/roles';

export const PAYSTONE_DOMAIN = 'paystone.com';
export const GIVEPOINT_URL = `https://givepoint.${PAYSTONE_DOMAIN}`;

/** Dummy email to attach paystone hub companies that don't user */
export const PAYMENTS_HUB_ORPHAN_COMPANY_USER_EMAIL = 'paystone-dummy-user-xpazijl@paystone.com';
export const USER_ROLES_WITH_ACCESS_TO_PAYMENT_REPORTING = [
    UserRole.OWNER,
    UserRole.MANAGER_PLUS_PAYMENT,
];
