export enum UserRole {
    OWNER = 'OWNER',
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum CompanyRole {
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum AccountRole {
    OWNER = 'OWNER',
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum CompanyRoleEditOption {
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',
    DELETE = 'DELETE',
}
export enum AccountRoleEditOption {
    OWNER = 'OWNER',
    MANAGER_PLUS_PAYMENT = 'MANAGER_PLUS_PAYMENT',
    MANAGER = 'MANAGER',
    TEAM_MEMBER = 'TEAM_MEMBER',
    DELETE = 'DELETE',
}
