import { useMutation } from '@apollo/client';
import { IClientSafeOnboardingState } from '@nicejob-library/data-platform-implementation/entities/OnboardingState';
import { IAddress } from '@nicejob-library/data-platform-implementation/entities/common';
import { translate } from '@nicejob-library/internationalization';
import { IValidationRules, Validation } from '@nicejob-library/react-form-validation';
import { IValidationError } from '@nicejob-library/react-form-validation/src/Validation';
import { useMutationWithRetry } from '@nicejob-library/react-hooks';
import { trackClick } from '@nicejob-library/tracking';
import produce from 'immer';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import * as E from '../../../../../../../types/entities.types';
import { VALIDATION_FIELD } from '../../../shared/types';
import { useOnboardingState } from '../../context/OnboardingStateContext';
import { formatAddressAsPrediction } from '../../formatters/formatAddressAsPrediction';
import ONBOARDING_STATE_QUERIES from '../../queries/OnboardingState';
import { isAdditionalInfoComplete } from './isAdditionalInfoComplete';

/** Components */
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faBalloons } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, LinkButton, TextField } from '@nicejob-library/design-system/core';
import { InputFieldAndLabelContainer } from '../../Onboarding.styles';
import { ManualAddress } from '../../components/ManualAddress';
import {
    AdditionalInfoContainer,
    AlertContainer,
    BackButtonContainer,
    BalloonsIconContainer,
    ConnectGBPBackContainer,
    HeaderTextContainer,
    InputContainer,
    LeftArrowContainer,
    LinkButtonContainer,
    MainContainer,
    MainTextContainer,
    WelcomeTextContainer,
} from './AdditionalInfo.styles';
import { AutoCompleteAddress } from './ChildComponents/AutoCompleteAddress';
import { BackgroundVisuals } from './ChildComponents/BackgroundVisuals';

/** Types */
interface IAdditionalInfoProps {
    onboarding_state_id: string;
    company_name: string | null;
    company_address: IAddress | null;
    setCurrentCompanyName: (current_company_name: string) => void;
}

/** Globals */
const VALIDATION_RULES: IValidationRules = {
    [VALIDATION_FIELD.company_name]: {
        type: 'company_name',
        label: translate({ namespace: 'common', key: 'textfield.company_name' }),
        required: true,
    },
    [VALIDATION_FIELD.company_address]: {
        label: translate({
            namespace: 'login',
            key: 'onboarding.register_company.company_address',
        }),
        required: true,
    },
    [VALIDATION_FIELD.street_address]: {
        label: translate({
            namespace: 'login',
            key: 'onboarding.register_company.manual_address.street',
        }),
        required: true,
    },
    [VALIDATION_FIELD.zip]: {
        label: translate({
            namespace: 'login',
            key: 'common.textfield.postal_code',
        }),
        required: true,
    },
    [VALIDATION_FIELD.country]: {
        label: translate({ namespace: 'common', key: 'text.country' }),
        required: true,
    },
};

export function AdditionalInfo(props: IAdditionalInfoProps): ReactElement {
    const { onboarding_state_id, company_name, company_address, setCurrentCompanyName } = props;

    const {
        onboarding_state,
        onOnboardingStateChange,
        onboarding_state_error,
        setOnboardingStateError,
        setOnboardingUpdateIsLoading,
    } = useOnboardingState();

    const [current_company_name, setCompanyName] = useState<IAdditionalInfoProps['company_name']>(
        company_name
    );
    const [current_company_address, setCompanyAddress] = useState<
        Partial<IAdditionalInfoProps['company_address']>
    >({
        ...company_address,

        // initialize explicit null defaults
        geo: null,
        timezone: null,
        googlePlaceId: null,
    });
    const [continue_button_is_enabled, setContinueButtonIsEnabled] = useState<boolean>(false);
    const [show_manual_address, setShowManualAddress] = useState<boolean>(false);
    const [validation_errors, setValidationErrors] = useState<Partial<IValidationError>>({});
    const [should_disable_validation, setShouldDisableValidation] = useState(false);

    const [updateOnboardingState] = useMutation(
        ONBOARDING_STATE_QUERIES.Mutation.updateOnboardingState
    );

    const [updateOnboardingStateWithRetry, { loading }] = useMutationWithRetry({
        mutation: ONBOARDING_STATE_QUERIES.Mutation.updateOnboardingState,
        options: {
            variables: {
                input: {
                    onboarding_state_id,
                    form_data: {
                        company_name: current_company_name,
                        company_address: current_company_address,
                        company_phone: null,
                    },
                },
            },
            onCompleted: response => {
                if (response.data.updateOnboardingState) {
                    setOnboardingStateError(false);
                    setOnboardingUpdateIsLoading(false);
                    onOnboardingStateChange(response.data.updateOnboardingState);
                }
            },
            onError: () => {
                if (onboarding_state) {
                    setOnboardingStateError(true);

                    onOnboardingStateChange({ ...onboarding_state });
                }
            },
        },
        max_retry: 3,
    });

    const validation = useMemo(
        () =>
            new Validation({
                rules: VALIDATION_RULES,
                onValidation,
            }),
        []
    );

    function onGPBBackClick(): void {
        trackClick({
            id: 'onboarding_google_back',
            type: 'action',
            is_anonymous: true,
        });

        updateOnboardingState({
            variables: {
                input: {
                    onboarding_state_id,
                    has_aborted_google_connect: false,
                },
            },
        });

        onboarding_state &&
            onOnboardingStateChange({ ...onboarding_state, has_aborted_google_connect: false });
    }

    function onAutoCompleteAddressSelected(chosen_address: IAddress): void {
        const clean_address = removeTypenameFieldFromAddress(chosen_address);

        setCompanyAddress(clean_address);
    }

    function onManualAddressChanged(updated_address: Partial<IAddress>): void {
        setCompanyAddress(prev => ({
            ...prev,
            ...updated_address,
        }));
    }

    function onCompanyNameChanged(new_company_name: string): void {
        clearValidationError(VALIDATION_FIELD.company_name);

        setCompanyName(new_company_name);
        setCurrentCompanyName(new_company_name);
    }

    function onEnterAddressManuallyClicked(): void {
        clearValidationError(VALIDATION_FIELD.company_address);
        setShowManualAddress(true);
    }

    function onValidation(errors: Partial<IValidationError>): void {
        setValidationErrors({ ...errors });
    }

    function clearValidationError(field: VALIDATION_FIELD): void {
        const new_validation_errors = { ...validation_errors };
        delete new_validation_errors[field];
        if (validation.errors) {
            delete validation.errors[field];
        }
        setValidationErrors(new_validation_errors);
    }

    function enableValidation(): void {
        setShouldDisableValidation(false);
    }

    const onContinueClick = useCallback(async () => {
        if (!onboarding_state || !current_company_address) {
            // eslint-disable-next-line no-console
            return console.error('No onboarding state');
        }

        trackClick({
            id: 'onboarding_address_submit',
            type: 'action',
            is_anonymous: true,
        });

        /** Optimistically update onboarding_state */
        setOnboardingUpdateIsLoading(true);
        updateOnboardingStateWithRetry();

        const next_onboarding_state = produce<IClientSafeOnboardingState>(
            onboarding_state,
            onboarding_state_draft => {
                onboarding_state_draft.form_data.company_name = current_company_name;

                // necessary cast from Partial, by this point current_company_address must be complete
                onboarding_state_draft.form_data.company_address = company_address as IAddress;

                // set explicit nulls
                onboarding_state_draft.form_data.company_phone = null;
            }
        );

        onOnboardingStateChange(next_onboarding_state);
    }, [JSON.stringify(current_company_address), current_company_name, onboarding_state]);

    const address_auto_complete_initial_value = useMemo(
        () => formatAddressAsPrediction(current_company_address as IAddress),
        [current_company_address]
    );

    // when all required fields have been filled, enable the continue button
    useEffect(() => {
        const form_is_complete = isAdditionalInfoComplete({
            current_company_name,
            current_company_address,
            validation_errors,
        });

        setContinueButtonIsEnabled(form_is_complete);
    }, [current_company_name, JSON.stringify(current_company_address)]);

    return (
        <AdditionalInfoContainer>
            {/* Header */}
            <ConnectGBPBackContainer onClick={onGPBBackClick}>
                <LeftArrowContainer>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </LeftArrowContainer>
                <BackButtonContainer>
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.button.connect_gbp',
                    })}
                </BackButtonContainer>
            </ConnectGBPBackContainer>

            {/*  Main */}
            <MainContainer>
                <AlertContainer>
                    <Alert
                        variant='serious'
                        message={translate({
                            namespace: 'login',
                            key: 'onboarding.register_company.error',
                        })}
                        show_alert={onboarding_state_error}
                    />
                </AlertContainer>

                <BalloonsIconContainer>
                    <FontAwesomeIcon icon={faBalloons} />
                </BalloonsIconContainer>

                <MainTextContainer>
                    <WelcomeTextContainer>
                        {translate({
                            namespace: 'login',
                            key: 'common.header.welcome',
                        })}
                    </WelcomeTextContainer>
                    <HeaderTextContainer>
                        {translate({
                            namespace: 'login',
                            key: 'onboarding.register_company.description',
                        })}
                    </HeaderTextContainer>
                </MainTextContainer>

                <InputContainer>
                    <InputFieldAndLabelContainer>
                        <TextField
                            label={translate({
                                namespace: 'common',
                                key: 'textfield.company_name',
                            })}
                            variant='stretch'
                            name='company_name'
                            initial_value={current_company_name || ''}
                            onChange={onCompanyNameChanged}
                            error={validation_errors.company_name?.message}
                            onBlur={(e): void => {
                                !should_disable_validation && validation.input(e);
                            }}
                            onFocus={enableValidation}
                            invalid={!!validation_errors.company_name}
                        />
                    </InputFieldAndLabelContainer>
                </InputContainer>

                {!show_manual_address && (
                    <InputFieldAndLabelContainer>
                        <AutoCompleteAddress
                            onAddressChosen={onAutoCompleteAddressSelected}
                            initial_address_prompt={address_auto_complete_initial_value}
                            validation={validation}
                            validation_errors={validation_errors}
                            clearValidationError={clearValidationError}
                            should_disable_validation={should_disable_validation}
                            onFocus={enableValidation}
                        />
                        <LinkButtonContainer
                            onMouseDown={(): void => {
                                setShouldDisableValidation(true);
                            }}
                        >
                            <LinkButton onClick={onEnterAddressManuallyClicked}>
                                {translate({
                                    namespace: 'login',
                                    key: 'onboarding.register_company.button.add_address_manually',
                                })}
                            </LinkButton>
                        </LinkButtonContainer>
                    </InputFieldAndLabelContainer>
                )}

                {show_manual_address && (
                    <InputFieldAndLabelContainer>
                        <ManualAddress
                            variant={'modal'}
                            company_address={current_company_address}
                            onChange={onManualAddressChanged}
                            validation={validation}
                            validation_errors={validation_errors}
                            clearValidationError={clearValidationError}
                        />
                    </InputFieldAndLabelContainer>
                )}

                <Button
                    variant='action'
                    disabled={!continue_button_is_enabled}
                    loading={loading}
                    onClick={onContinueClick}
                >
                    {translate({
                        namespace: 'login',
                        key: 'onboarding.register_company.button.continue',
                    })}
                </Button>
            </MainContainer>

            {/* Graphics */}
            <BackgroundVisuals show_manual_address={show_manual_address} />
        </AdditionalInfoContainer>
    );
}

function removeTypenameFieldFromAddress(address: E.Address): IAddress {
    const {
        address: address_line_1,
        city,
        zip,
        country,
        unit,
        geo,
        timezone,
        state,
        googlePlaceId,
    } = address;

    const return_address: IAddress = {
        address: address_line_1,
        city,
        zip,
        country,
        unit,
        timezone: timezone || null,
        googlePlaceId: googlePlaceId || null,
        state,
        geo: geo
            ? {
                  latitude: geo.latitude,
                  longitude: geo.longitude,
              }
            : null,
    };

    return return_address;
}
